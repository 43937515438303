<template>
	<div class="content_out">
		<div class="top_title_out">
			<img
				class="top_title_bg"
				src="../../assets/image/pc/home/top_title_bg.png"
				alt=""
			/>
			<div class="top_title_content">
				<div class="top_title_inner">
					<div class="top_title_people">
						<img
							class="top_title_people_img"
							src="../../assets/image/pc/home/top_title_people_img.png"
							alt=""
						/>
						<div class="top_title_people_bubble"></div>
					</div>
					<div class="top_title">
						<img
							class="top_title_img"
							src="../../assets/image/pc/home/top_title_img.png"
							alt=""
						/>
						<img
							class="top_title_text_img"
							src="../../assets/image/pc/home/top_title_text_img.png"
							alt=""
						/>
						<div class="top_title_des_img flexCenter">
							选择聚趣 让学习更有趣
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="hot_recruitment_courses">
			<div class="title_box_out">
				<div class="title_out flexVerticalCenter">
					<img
						class="title_affix_icon"
						src="../../assets/image/pc/home/title_affix_icon.png"
						alt=""
					/>
					<div class="title_box flexVerticalCenter">
						<div class="title_text">
							聚趣雅思<span style="color:#E31837">春季</span
							>热招课程
						</div>
						<img
							class="title_bg_img"
							src="../../assets/image/pc/home/title_bg_img.png"
							alt=""
						/>
					</div>
					<img
						class="title_affix_icon"
						src="../../assets/image/pc/home/title_affix_icon.png"
						alt=""
					/>
				</div>
				<div class="title_des">线上课程短期密集高效学习</div>
			</div>
			<div class="hot_recruitment_courses_list flexVerticalCenter">
				<div
					class="hot_recruitment_courses_item"
					v-for="(item, i) in hotRecruitmentCourses"
					:key="i"
				>
					<img class="item_img" :src="item.img" alt="" />
					<div class="item_title">{{ item.title }}</div>
					<div class="item_des">{{ item.des }}</div>
				</div>
			</div>
		</div>
		<div class="IELTS_app_bg">
			<div class="IELTS_app">
				<div class="IELTS_app_info">
					<div class="IELTS_app_title">{{ IELTSApp.title }}</div>
					<div class="IELTS_app_des">
						{{ IELTSApp.des }}
					</div>
					<div class="IELTS_app_intro">
						<div class="IELTS_app_intro_text">
							<span>{{ IELTSApp.intro }}</span>
						</div>
						<img
							class="IELTS_app_intro_bg"
							src="../../assets/image/pc/home/IELTS_app_intro_bg.png"
							alt=""
						/>
					</div>
				</div>
				<img
					class="IELTS_app_img"
					src="../../assets/image/pc/home/IELTS_app_img.png"
					alt=""
				/>
			</div>
		</div>
		<div class="service flexCenter">
			<img
				class="service_img"
				src="../../assets/image/pc/home/service_img.png"
				alt=""
			/>
			<div class="service_content">
				<div class="service_content_text flexBetween">
					<div class="flexBetween" style="width:66px">
						<div>更</div>
						<div>懂</div>
					</div>
					<div>THE</div>
				</div>
				<div class="service_content_text flexBetween">
					<div class="flexBetween" style="width:66px">
						<div>雅</div>
						<div>思</div>
					</div>
					<div>MAJOR</div>
				</div>
			</div>
		</div>
		<div class="keynote_team">
			<div class="title_box_out">
				<div class="title_out flexVerticalCenter">
					<img
						class="title_affix_icon"
						src="../../assets/image/pc/home/title_affix_icon.png"
						alt=""
					/>
					<div class="title_box flexVerticalCenter">
						<div class="title_text">
							聚趣雅思<span style="color:#E31837">主讲</span>团队
						</div>
						<img
							class="title_bg_img"
							src="../../assets/image/pc/home/title_bg_img.png"
							alt=""
						/>
					</div>
					<img
						class="title_affix_icon"
						src="../../assets/image/pc/home/title_affix_icon.png"
						alt=""
					/>
				</div>
				<div class="title_des">资深海归领衔雅思授课</div>
			</div>
			<div class="keynote_team_bg">
				<img
					class="keynote_team_box_bg"
					src="../../assets/image/pc/home/keynote_team_box_bg.png"
					alt=""
				/>
				<div class="keynote_team_box">
					<div class="keynote_team_picture">
						<img
							class="keynote_team_picture_img1"
							src="../../assets/image/pc/home/keynote_team_picture_img1.png"
							alt=""
						/>
						<img
							class="keynote_team_pictureBig"
							:src="currentKeynoteTeam.pictureBig2"
							alt=""
						/>
						<div class="keynote_team_picture_img2_box">
							<img
								class="keynote_team_picture_img2"
								src="../../assets/image/pc/home/keynote_team_picture_img2.png"
								alt=""
							/>
							<div class="keynote_team_picture_img_bg bg1"></div>
							<div class="keynote_team_picture_img_bg bg2"></div>
							<div class="keynote_team_picture_img_bg bg3"></div>
						</div>
					</div>
					<div class="keynote_team_info">
						<div class="keynote_team_name_box">
							<img
								class="keynote_team_name_bg title_bg_img"
								src="../../assets/image/pc/home/title_bg_img.png"
								alt=""
							/>
							<div class="keynote_team_name">
								{{ currentKeynoteTeam.name }}
								<span class="keynote_team_typeName">{{
									currentKeynoteTeam.typeName
								}}</span>
							</div>
						</div>
						<div class="keynote_team_label_box">
							<div
								class="keynote_team_label flexVerticalCenter"
								v-for="(item,
								i) in currentKeynoteTeam.labelList"
								:key="i"
							>
								<img
									class="keynote_team_label_bg"
									src="../../assets/image/pc/home/keynote_team_label_left_bg.png"
									alt=""
								/>
								<div
									class="keynote_team_label_text flexVerticalCenter"
								>
									<span>{{ item }}</span>
								</div>
								<img
									class="keynote_team_label_bg"
									src="../../assets/image/pc/home/keynote_team_label_right_bg.png"
									alt=""
								/>
							</div>
						</div>
						<div class="keynote_team_intro">
							<span>{{ currentKeynoteTeam.intro }}</span>
						</div>
						<div
							class="keynote_team_teacher_list_out_out flexBetween"
						>
							<!-- <div
								class="keynote_team_teacher_btn"
								@click="fnPrev()"
								v-if="canScroll"
							> -->
							<div
								class="keynote_team_teacher_btn"
								@click="fnPrev()"
							>
								<img
									v-if="currentClickNumber > 1"
									class="btn_active_icon"
									src="../../assets/image/pc/home/left_active_icon.png"
									alt=""
								/>
								<img
									v-else
									class="btn_icon"
									src="../../assets/image/pc/home/left_icon.png"
									alt=""
								/>
							</div>
							<!-- <div
								class="keynote_team_teacher_list_out flexBetween"
								:style="{ padding: canScroll ? '0 24px' : '0' }"
							> -->
							<div
								class="keynote_team_teacher_list_out flexBetween"
							>
								<div
									class="keynote_team_teacher_list_box"
									:ref="`fixedBox`"
								>
									<div
										class="keynote_team_teacher_list flexCenter"
										:style="
											`width:${signleWidth *
												keynoteTeam.length} px;transform:translate(${scrollResultWidth}px,0);transition:0.7s;`
										"
									>
										<img
											class="keynote_team_teacher_imgs
											"
											:src="item.pictureSmall"
											alt=""
											v-for="(item, i) in keynoteTeam"
											:key="i"
											@click="selectTeacher(item)"
										/>
									</div>
								</div>
							</div>
							<!-- <div
								class="keynote_team_teacher_btn"
								@click="fnNext()"
								v-if="canScroll"
							> -->
							<div
								class="keynote_team_teacher_btn"
								@click="fnNext()"
							>
								<img
									v-if="noScrollRight"
									class="btn_active_icon"
									src="../../assets/image/pc/home/right_active_icon.png"
									alt=""
								/>
								<img
									v-else
									class="btn_icon"
									src="../../assets/image/pc/home/right_icon.png"
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="live_interaction">
			<div class="title_box_out">
				<div class="title_out flexVerticalCenter">
					<img
						class="title_affix_icon"
						src="../../assets/image/pc/home/title_affix_icon.png"
						alt=""
					/>
					<div class="title_box flexVerticalCenter">
						<div class="title_text">
							聚趣雅思<span style="color:#E31837">直播互动</span
							>课程
						</div>
						<img
							class="title_bg_img"
							src="../../assets/image/pc/home/title_bg_img.png"
							alt=""
						/>
					</div>
					<img
						class="title_affix_icon"
						src="../../assets/image/pc/home/title_affix_icon.png"
						alt=""
					/>
				</div>
				<div class="title_des">不受时间、地域限制，方便学雅思</div>
			</div>
			<div class="live_interaction_title flexVerticalCenter">
				<div
					:class="
						liveInteractionIndex == i
							? 'live_interaction_title_item live_interaction_title_item_bg'
							: 'live_interaction_title_item'
					"
					v-for="(item, i) in liveInteraction"
					:key="i"
					@click="selectLiveInteraction(item, i)"
				>
					<img
						class="live_interaction_title_item_icon"
						:src="
							liveInteractionIndex == i
								? item.activeIcon
								: item.icon
						"
						alt=""
					/>
					{{ item.title }}
				</div>
			</div>
			<div
				class="live_interaction_content_bg"
				:style="{
					padding: liveInteractionIndex == 0 ? '50px 0' : '0 0'
				}"
			>
				<div
					class="live_interaction_content"
					:style="{
						background: liveInteractionIndex == 0 ? '' : '#FFF1F1'
					}"
				>
					<div
						class="live_interaction_content_item"
						v-for="(item, i) in currentLiveInteraction.content"
						:key="i"
						v-show="liveInteractionIndex + 1 == 1"
					>
						<div
							class="live_interaction_content_item_theme flexVerticalCenter"
						>
							{{ item.theme }}
						</div>
						<div class="live_interaction_content_item_content_bg">
							<div
								class="live_interaction_content_item_content"
								v-for="(item2, j) in item.content"
								:key="j"
							>
								<span
									class="live_interaction_content_item_content_title"
									:style="{
										marginRight: item2.text ? '20px' : '0'
									}"
									>{{ item2.title }}</span
								><span>{{ item2.text }}</span>
							</div>
						</div>
					</div>
					<div
						class="live_interaction_content_img_out"
						v-if="
							liveInteractionIndex + 1 == 2 ||
								liveInteractionIndex + 1 == 4
						"
					>
						<img
							class="live_interaction_content_img img2and4"
							mode="widthFix"
							:src="currentLiveInteraction.content"
							alt=""
						/>
					</div>
					<div
						class="live_interaction_content_img_out flexVerticalCenter"
						v-if="
							liveInteractionIndex + 1 == 3 ||
								liveInteractionIndex + 1 == 5
						"
					>
						<img
							v-for="(item, i) in currentLiveInteraction.content"
							:key="i"
							class="live_interaction_content_img"
							mode="widthFix"
							:src="item"
							alt=""
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="overseas_study">
			<div class="title_box_out">
				<div class="title_out flexVerticalCenter">
					<img
						class="title_affix_icon"
						src="../../assets/image/pc/home/title_affix_icon.png"
						alt=""
					/>
					<div class="title_box flexVerticalCenter">
						<div class="title_text">
							留学申请雅思<span style="color:#E31837">成绩</span
							>要求
						</div>
						<img
							class="title_bg_img"
							src="../../assets/image/pc/home/title_bg_img.png"
							alt=""
						/>
					</div>
					<img
						class="title_affix_icon"
						src="../../assets/image/pc/home/title_affix_icon.png"
						alt=""
					/>
				</div>
				<div class="title_des">不受时间、地域限制，方便学雅思</div>
			</div>
			<div class="overseas_study_content">
				<img
					class="overseas_study_img"
					src="../../assets/image/pc/home/overseas_study_img.png"
					alt=""
				/>
				<img
					class="overseas_study_bg"
					src="../../assets/image/pc/home/overseas_study_bg.png"
					alt=""
				/>
				<img
					:class="'overseas_study_item img' + i"
					v-for="(item, i) in overseasStudy"
					:key="i"
					:src="item"
					alt=""
				/>
			</div>
		</div>
	</div>
</template>

<script>
const axios = require('axios')
export default {
	data() {
		return {
			hotRecruitmentCourses: [
				{
					title: '雅思基础班',
					des:
						'学习计划定制\n语法提点+词汇精炼\n上课提醒+作业答疑\n每周真题讲解+考场回顾',
					img: require('@/assets/image/pc/home/hot_recruitment_courses1.png')
				},
				{
					title: '雅思强化班',
					des:
						'学习计划定制\n上课提醒+1v1督学\n口写作业精批+答疑\n每周真题讲解+考场回顾',
					img: require('@/assets/image/pc/home/hot_recruitment_courses2.png')
				},
				{
					title: '雅思冲刺班',
					des:
						'学习计划定制\n上课提醒+1V1督学\n写作、口语点评和答疑\n每周真题讲解+考场回顾',
					img: require('@/assets/image/pc/home/hot_recruitment_courses3.png')
				},
				{
					title: '1V1定制班',
					des:
						'vip个性化精导和答疑\n专属学习计划制定\n写作、口语精批和修改\n每周真题讲解+考场回顾',
					img: require('@/assets/image/pc/home/hot_recruitment_courses4.png')
				}
			],
			IELTSApp: {
				title: '聚趣雅思-APP',
				des:
					'集合剑桥雅思练习题库，雅思机经及答案解析，AI精听跟读，万人 交流社区，斩单词的核心功能。',
				intro:
					'-超全题库 : 剑雅系列题库,历年考试真题,高分解析,视频带练\n-精听跟读 : 超多跟读素材,实时测评打分,口语听力双进阶神器\n-技巧微课 : 从零到进阶的必备学习课程,细致到每一个知识点'
			},
			keynoteTeam: [],
			currentKeynoteTeam: {},
			testList: [],
			scrollResultWidth: 0, //transform滚动的距离
			signleWidth: 100, //单个流程的宽度
			currentClickNumber: 1,
			noScrollRight: true,
			canScroll: true,
			liveInteraction: [
				{
					id: 1,
					title: '学习流程',
					icon: require('@/assets/image/pc/home/live_interaction_icon1.png'),
					activeIcon: require('@/assets/image/pc/home/live_interaction_active_icon1.png'),
					content: [
						{
							theme: '课前',
							content: [
								{
									title: '课前班会',
									text:
										'讲解本次直播内容利用导学资料提前熟悉课程'
								}
							]
						},
						{
							theme: '课中',
							content: [
								{
									title: '直播学习',
									text: ''
								},
								{
									title: '主讲直播',
									text: '讲解直播及互动操作'
								},
								{
									title: '30天冲刺计划',
									text: '听说读写全逐个击破 '
								},
								{
									title: '拖延克星',
									text: '助教到点提醒上课'
								}
							]
						},
						{
							theme: '课后',
							content: [
								{
									title: '1v1 辅导',
									text: '口语纠音,写作提升,失分点讲解'
								},
								{
									title: '直播回放',
									text: '无限回看,针对性巩固提升'
								},
								{
									title: '考前冲刺',
									text: '查漏补缺 考点突击 模考解析'
								}
							]
						},
						{
							theme: '参加考试',
							content: [
								{
									title: '通过考试',
									text: '申请奖学金'
								},
								{
									title: '未达目标分数',
									text: '申请免费重读'
								}
							]
						}
					]
				},
				{
					id: 2,
					title: '课堂实况',
					icon: require('@/assets/image/pc/home/live_interaction_icon2.png'),
					activeIcon: require('@/assets/image/pc/home/live_interaction_active_icon2.png'),
					content: require('@/assets/image/pc/home/live_interaction_content2.png')
				},
				{
					id: 3,
					title: '口语模考',
					icon: require('@/assets/image/pc/home/live_interaction_icon3.png'),
					activeIcon: require('@/assets/image/pc/home/live_interaction_active_icon3.png'),
					content: [
						require('@/assets/image/pc/home/live_interaction_content3_1.png'),
						require('@/assets/image/pc/home/live_interaction_content3_2.png'),
						require('@/assets/image/pc/home/live_interaction_content3_3.png')
					]
				},
				{
					id: 4,
					title: '写作批改',
					icon: require('@/assets/image/pc/home/live_interaction_icon4.png'),
					activeIcon: require('@/assets/image/pc/home/live_interaction_active_icon4.png'),
					content: require('@/assets/image/pc/home/live_interaction_content4.png')
				},
				// {
				// 	id: 5,
				// 	title: '学习档案',
				// 	icon: require('@/assets/image/pc/home/live_interaction_icon5.png'),
				// 	activeIcon: require('@/assets/image/pc/home/live_interaction_active_icon5.png'),
				// 	content: []
				// },
				{
					id: 6,
					title: '微信答疑',
					icon: require('@/assets/image/pc/home/live_interaction_icon6.png'),
					activeIcon: require('@/assets/image/pc/home/live_interaction_active_icon6.png'),
					content: [
						require('@/assets/image/pc/home/live_interaction_content6_1.png'),
						require('@/assets/image/pc/home/live_interaction_content6_2.png'),
						require('@/assets/image/pc/home/live_interaction_content6_3.png')
					]
				}
			],
			currentLiveInteraction: {},
			liveInteractionIndex: 0,
			overseasStudy: [
				require('@/assets/image/pc/home/overseas_study1.png'),
				require('@/assets/image/pc/home/overseas_study2.png'),
				require('@/assets/image/pc/home/overseas_study3.png'),
				require('@/assets/image/pc/home/overseas_study4.png'),
				require('@/assets/image/pc/home/overseas_study5.png'),
				require('@/assets/image/pc/home/overseas_study6.png'),
				require('@/assets/image/pc/home/overseas_study7.png'),
				require('@/assets/image/pc/home/overseas_study8.png'),
				require('@/assets/image/pc/home/overseas_study9.png')
			]
		}
	},
	created() {
		this.getTeacherIntroList()
		this.currentLiveInteraction = this.liveInteraction[0]
	},
	mounted() {
		window.addEventListener('scroll', this.windowScroll) //监听页面滚动
	},
	destroyed() {
		// 在组件生命周期结束的时候销毁。
		window.removeEventListener('scroll', this.windowScroll)
	},
	methods: {
		//初始化判断是否可以向右滚动
		initgoRightArrow() {
			const currentScrollWidth = this.$refs[`fixedBox`].clientWidth
			const canNumber = Math.floor(currentScrollWidth / this.signleWidth) //可以放下的个数
			//如果最后一个流程图标已经展示出来，则停止滚动
			if (
				this.currentClickNumber + canNumber >=
				this.keynoteTeam.length
			) {
				this.noScrollRight = false
				this.canScroll = false
			} else {
				this.noScrollRight = true
				this.canScroll = true
			}
		},
		//点击上一个
		fnPrev() {
			//如果右点击的次数大于1，才可以左滚
			if (this.currentClickNumber > 1) {
				this.currentClickNumber -= 1
				this.noScrollRight = true
				this.fnScrollWidth('reduce')
			} else {
				return false
			}
		},
		//点击下一个
		fnNext() {
			const currentScrollWidth = this.$refs[`fixedBox`].clientWidth
			const canNumber = Math.floor(currentScrollWidth / this.signleWidth) //可以放下的个数
			//如果最后一个流程图标已经展示出来，则停止滚动
			if (
				this.currentClickNumber + canNumber >=
				this.keynoteTeam.length
			) {
				return
			}
			//说明放不下有滚动条
			if (this.keynoteTeam.length > canNumber) {
				this.currentClickNumber += 1
				if (
					this.currentClickNumber + canNumber >=
					this.keynoteTeam.length
				) {
					this.noScrollRight = false
				}
				this.fnScrollWidth('add')
			}
		},
		//translate的宽度
		fnScrollWidth(type) {
			let result = 0
			if (type === 'reduce') {
				result = 97
			} else if (type === 'add') {
				result = -97
			} else {
				result = 0
			}
			this.scrollResultWidth += result
		},
		// 选择老师
		selectTeacher(item) {
			this.currentKeynoteTeam = item
		},
		// 选择直播互动
		selectLiveInteraction(item, i) {
			this.currentLiveInteraction = item
			this.liveInteractionIndex = i
		},
		// 获取师资
		async getTeacherIntroList() {
			var params = new URLSearchParams()
			params.append('page', 0)
			params.append('size', 99999)
			params.append('sort', 'sort,asc')
			axios
				.post(
					'https://ke.juquedu.com:8007/school/schoolTeacherIntro/un/getlist',
					params
				)
				.then((res) => {
					var data = []
					data = res.data.data.content
					var keynoteTeam = []
					data.forEach((item) => {
						var list = item.intro.split('<标>')
						var intro = list[0]
						var labelList = []
						for (let index = 1; index < list.length; index++) {
							var label = list[index].split('<签>')[0]
							var label2 = list[index].split('<签>')[1]
							var label3 = label + label2
							intro += label3
							labelList.push(label)
						}
						item.labelList = labelList
						item.intro = intro
						if (item.languageid == 6) {
							keynoteTeam.push(item)
						}
					})
					this.keynoteTeam = keynoteTeam
					this.currentKeynoteTeam = this.keynoteTeam[0]
					this.$nextTick(() => {
						this.initgoRightArrow()
					})
				})
		},
		// 监听页面滚动 获取滚动条位置
		windowScroll() {
			// let scrollTop =
			// 	window.pageYOffset ||
			// 	document.documentElement.scrollTop ||
			// 	document.body.scrollTop
			// console.log(scrollTop)
		}
	}
}
</script>

<style lang="scss" scoped>
.content_out {
	min-width: 1200px;
	padding-top: 80px;
	overflow-x: hidden;
	.top_title_out {
		width: 100%;
		height: 560px;
		position: relative;
		.top_title_bg {
			width: 1920px;
			position: absolute;
			top: 0;
			left: 50%;
			margin-left: -960px;
		}
		.top_title_content {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			.top_title_inner {
				width: 1200px;
				height: 100%;
				margin: 0 auto;
				display: flex;
				.top_title_people {
					width: 317px;
					height: 317px;
					padding-top: 101px;
					position: relative;
					.top_title_people_img {
						width: 317px;
						height: 317px;
					}
					.top_title_people_bubble {
						width: 109px;
						height: 109px;
						background: #ffffff;
						opacity: 0.8;
						position: absolute;
						right: -20px;
						bottom: -35px;
						border-radius: 50%;
					}
				}
				.top_title {
					padding-top: 154px;
					margin-left: 106px;
					.top_title_img {
						width: 350px;
						height: 57px;
					}
					.top_title_text_img {
						width: 768px;
						height: 97px;
						margin: 20px 0 50px;
					}
					.top_title_des_img {
						width: 544px;
						height: 45px;
						font-size: 29px;
						font-weight: 400;
						color: #ffffff;
						background-image: url('../../assets/image/pc/home/top_title_des_img.png');
						background-size: cover;
						padding: 0 10px;
						box-sizing: border-box;
					}
				}
			}
		}
	}
	.title_box_out {
		margin: 0 auto;
		.title_out {
			.title_affix_icon {
				width: 70px;
				height: 18px;
			}
			.title_box {
				height: 100px;
				margin: 0 30px;
				position: relative;
				.title_text {
					font-size: 36px;
					font-weight: bold;
					color: #000000;
					z-index: 2;
				}
			}
		}
		.title_des {
			font-size: 18px;
			font-weight: 400;
			color: #000000;
			text-align: center;
		}
	}
	.title_bg_img {
		width: 248px;
		height: 100px;
		position: absolute;
	}
	.hot_recruitment_courses {
		width: 1200px;
		margin: 40px auto 80px;
		.hot_recruitment_courses_list {
			margin-top: 60px;
			.hot_recruitment_courses_item {
				width: 261px;
				height: 320px;
				background: #ffffff;
				box-shadow: 0px 3px 10px 1px rgba(164, 91, 91, 0.16);
				border-radius: 8px;
				margin-right: 52px;
				text-align: center;
				.item_img {
					width: 100%;
					height: 100px;
				}
				.item_title {
					font-size: 26px;
					font-weight: 800;
					color: #000000;
					margin: 20px 0 16px;
				}
				.item_des {
					font-size: 18px;
					font-weight: 400;
					color: #000000;
					white-space: pre-wrap;
					line-height: 30px;
				}
			}
			.hot_recruitment_courses_item:last-child {
				margin: 0;
			}
		}
	}
	.IELTS_app_bg {
		width: 100%;
		background-image: url('../../assets/image/pc/home/IELTS_app_bg.png');
		background-size: cover;
		.IELTS_app {
			width: 1200px;
			margin: 0 auto;
			padding: 60px 0;
			display: flex;
			.IELTS_app_info {
				width: 575px;
				padding-top: 60px;
				.IELTS_app_title {
					font-size: 18px;
					font-weight: 400;
					color: #e31837;
				}
				.IELTS_app_des {
					font-size: 24px;
					font-weight: 400;
					color: #000000;
					margin: 20px 0 40px;
				}
				.IELTS_app_intro {
					position: relative;
					.IELTS_app_intro_text {
						font-size: 20px;
						font-weight: 400;
						color: #ffffff;
						line-height: 42px;
						padding: 20px 16px 0;
						box-sizing: border-box;
						white-space: pre-wrap;
						position: relative;
						z-index: 2;
					}
					.IELTS_app_intro_bg {
						width: 575px;
						height: 162px;
						position: absolute;
						top: 0;
						z-index: 1;
					}
				}
			}
			.IELTS_app_img {
				width: 610px;
				height: 512px;
				margin-left: 15px;
			}
		}
	}
	.service {
		width: 1200px;
		margin: 0 auto;
		padding: 80px 0;
		position: relative;
		.service_img {
			width: 968px;
			height: 188px;
		}
		.service_content {
			width: 305px;
			height: 97px;
			background: #e31837;
			position: absolute;
			right: 0;
			padding: 12px 16px 0;
			box-sizing: border-box;
			.service_content_text {
				font-size: 22px;
				font-weight: 400;
				color: #ffffff;
			}
			.service_content_text:first-child {
				margin-bottom: 13px;
			}
		}
	}
	.keynote_team {
		width: 100%;
		.keynote_team_bg {
			width: 100%;
			background: #f9f9f9;
			padding: 57px 0 61px;
			margin-top: 50px;
			position: relative;
			.keynote_team_box_bg {
				width: 904px;
				height: 630px;
				position: absolute;
				top: 0;
				left: 118px;
			}
			.keynote_team_box {
				width: 1200px;
				margin: 0 auto;
				display: flex;
				position: relative;
				.keynote_team_picture {
					width: 379px;
					height: 512px;
					position: relative;
					margin-right: 83px;
					.keynote_team_picture_img1 {
						width: 354px;
						height: 220px;
						position: absolute;
						left: -6px;
						bottom: 51px;
						z-index: 1;
					}
					.keynote_team_pictureBig {
						width: 355px;
						height: 459px;
						position: relative;
						z-index: 2;
					}
					.keynote_team_picture_img2_box {
						width: 367px;
						position: absolute;
						left: 0;
						bottom: 1px;
						z-index: 3;
						.keynote_team_picture_img2 {
							width: 367px;
							height: 199px;
							position: relative;
							z-index: 2;
						}
						.keynote_team_picture_img_bg {
							background: #f9f9f9;
							position: absolute;
							bottom: -61px;
							z-index: 1;
						}
						.bg1 {
							width: 367px;
							height: 112px;
							left: 0;
						}
						.bg2 {
							width: 50px;
							height: 199px;
							right: 0;
						}
						.bg3 {
							width: 7px;
							height: 143px;
							left: 0;
						}
					}
				}
				.keynote_team_info {
					padding-top: 45px;
					.keynote_team_name_box {
						min-width: 248px;
						height: 100px;
						position: relative;
						.keynote_team_name_bg {
							position: absolute;
							left: 0;
							top: 0;
						}
						.keynote_team_name {
							font-size: 30px;
							font-weight: bold;
							color: #000000;
							position: relative;
							top: 45px;
							left: 0;
							.keynote_team_typeName {
								margin-left: 20px;
								font-size: 18px;
								font-weight: 400;
								color: #f4292b;
							}
						}
					}
					.keynote_team_label_box {
						display: table;
						margin: 6px 0 24px;
						.keynote_team_label {
							height: 39px;
							margin: 0 16px 16px 0;
							float: left;
							.keynote_team_label_text {
								height: 39px;
								font-size: 18px;
								font-weight: 400;
								color: #ffffff;
								background: linear-gradient(
									to right,
									#e31837,
									#fd9251
								);
								padding: 0 5px;
							}
							.keynote_team_label_bg {
								width: 10px;
								height: 39px;
							}
						}
						.keynote_team_label:last-child {
							margin-right: 0;
						}
					}
					.keynote_team_intro {
						max-width: 738px;
						min-height: 192px;
						font-size: 18px;
						font-weight: 400;
						color: #000000;
						white-space: pre-wrap;
					}
					.keynote_team_keynote_team_teacher {
						max-width: 738px;
						.keynote_team_keynote_team_teacher_item {
							width: 61px;
							height: 61px;
							margin-right: 40px;
							cursor: pointer;
						}
						.keynote_team_keynote_team_teacher_item:last-child {
							margin: 0;
						}
					}
					.keynote_team_teacher_list_out_out {
						width: 738px;
						height: 61px;
						.keynote_team_teacher_list_out {
							width: 678px;
							height: 61px;
							position: relative;
							padding: 0 16px;
							box-sizing: border-box;
							.keynote_team_teacher_list_box {
								width: 652px;
								flex: 1;
								overflow: hidden;
								.keynote_team_teacher_list {
									white-space: nowrap;
									.keynote_team_teacher_imgs {
										width: 61px;
										height: 61px;
										margin-right: 36px;
										border-radius: 50%;
										cursor: pointer;
									}
									.keynote_team_teacher_imgs:last-child {
										margin-right: 0;
									}
									.keynote_team_teacher_active {
										width: 90px;
										height: 90px;
									}
								}
							}
						}
						.keynote_team_teacher_btn {
							width: 30px;
							height: 30px;
							.btn_icon {
								width: 100%;
								height: 100%;
							}
							.btn_active_icon {
								width: 100%;
								height: 100%;
								cursor: pointer;
							}
						}
					}
				}
			}
		}
	}
	.live_interaction {
		width: 100%;
		padding-top: 90px;
		.live_interaction_title {
			width: 1200px;
			margin: 60px auto 40px;
			.live_interaction_title_item {
				width: 144px;
				height: 148px;
				background-size: 100% 100%;
				font-size: 26px;
				font-weight: bold;
				color: #000000;
				display: flex;
				align-items: center;
				flex-direction: column;
				cursor: pointer;
				margin-right: 120px;
				.live_interaction_title_item_icon {
					width: 40px;
					height: 40px;
					margin: 20px 0 12px;
				}
			}
			.live_interaction_title_item_bg {
				color: #ffffff;
				background-image: url('../../assets/image/pc/home/live_interaction_title_bg.png');
			}
		}
		.live_interaction_content_bg {
			width: 100%;
			background-image: url('../../assets/image/pc/home/live_interaction_content_bg.png');
			background-size: 100%;
			padding: 50px 0;
			box-sizing: border-box;
			.live_interaction_content {
				width: 1200px;
				margin: 0 auto;
				.live_interaction_content_item {
					width: 100%;
					display: flex;
					margin-bottom: 32px;
					.live_interaction_content_item_theme {
						width: 228px;
						background: #e31837;
						box-shadow: 0px 3px 10px 1px rgba(206, 133, 133, 0.3);
						font-size: 28px;
						font-weight: bold;
						color: #ffffff;
					}
					.live_interaction_content_item_content_bg {
						width: 973px;
						background: #ffffff;
						box-shadow: 0px 3px 10px 1px rgba(206, 133, 133, 0.3);
						display: table;
						padding: 24px 0 12px 21px;
						box-sizing: border-box;
						.live_interaction_content_item_content {
							float: left;
							margin: 0 50px 12px 0;
							font-size: 26px;
							font-weight: 400;
							color: #000000;
							.live_interaction_content_item_content_title {
								font-weight: bold;
								margin-right: 20px;
							}
						}
						.live_interaction_content_item_content:nth-child(2) {
							margin-right: 0;
						}
					}
				}
				.live_interaction_content_item:last-child {
					margin: 0;
				}
				.live_interaction_content_img_out {
					width: 100%;
					margin: 0 auto;
					padding: 20px;
					box-sizing: border-box;
					.live_interaction_content_img {
						width: 347px;
						margin-right: 50px;
					}
					.live_interaction_content_img:last-child {
						margin: 0;
					}
					.img2and4 {
						width: 100%;
						margin: 0;
					}
				}
			}
		}
	}
	.overseas_study {
		width: 1200px;
		margin: 0 auto;
		padding: 100px 0;
		.overseas_study_content {
			width: 100%;
			height: 733px;
			position: relative;
			margin-top: 60px;
			.overseas_study_img {
				width: 815px;
				height: 611px;
				position: absolute;
				top: 0;
				left: 282px;
				z-index: 2;
			}
			.overseas_study_bg {
				width: 100%;
				height: 446px;
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 1;
			}
			.overseas_study_item {
				position: absolute;
				z-index: 3;
			}
			.img0 {
				width: 222px;
				height: 222px;
				top: 14px;
				left: 187px;
			}
			.img1 {
				width: 93px;
				height: 93px;
				top: 52px;
				left: 781px;
			}
			.img2 {
				width: 172px;
				height: 172px;
				top: 108px;
				left: 901px;
			}
			.img3 {
				width: 135px;
				height: 135px;
				top: 212px;
				left: 0;
			}
			.img4 {
				width: 275px;
				height: 275px;
				top: 163px;
				left: 456px;
			}
			.img5 {
				width: 125px;
				height: 125px;
				top: 301px;
				right: 0;
			}
			.img6 {
				width: 180px;
				height: 180px;
				top: 330px;
				left: 145px;
			}
			.img7 {
				width: 93px;
				height: 93px;
				top: 507px;
				left: 379px;
			}
			.img8 {
				width: 213px;
				height: 213px;
				top: 366px;
				left: 784px;
			}
		}
	}
}
</style>
